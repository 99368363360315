@import url("https://api.fontshare.com/v2/css?f[]=general-sans@600&display=swap");
@import url("https://api.fontshare.com/v2/css?f[]=satoshi@400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-image: linear-gradient(135deg, #fff7f0, #fff7f0);
  -webkit-font-smoothing: antialiased;
}
